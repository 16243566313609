.heading {
  margin: 0;
  padding: 0px;
  background-color: black;
  color: white;
  text-align: center;
}

.heading > h1 {
  padding: 20px;
  margin: 0;
}

.margin-right {
  margin-right: 15px;
}

.osel-title {
  left: 20px !important;
}

.user-profile .material-icons {
  vertical-align: top !important;
  line-height: inherit !important;
}
